<template>
  <div class="setting_form clear">
    <add-pixel-modal ref="add_pixel_modal"></add-pixel-modal>

    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Retargeting Pixels</h2>
          </div>
          <div class="right mr-2 ml-auto">
            <guided-tour v-if="getGuidedTour.step10"></guided-tour>

            <button v-else @click.prevent="showAddPixelModal($refs.add_pixel_modal)"
                    class="btn---cta btn-blue with-shadow btn-round   btn-bold">
              <span>Add Pixel</span>
              <span class="icon_right">
                               <i
                                 class="circle_icon d-flex align-items-center justify-content-center  fal fa-plus  "></i>
                            </span>
            </button>

          </div>
          <div class="d-flex align-items-center">
            <div class="left">
              <div class="mr-2 search_input_block no_animation" :class="{ active_input: payload.search }">
                <input placeholder="Search Pixels" :value="payload.search" @input="debounceSearch" type="text"
                       class="search_input"/>
                <i class="fal fa-search"></i>
              </div>
            </div>
            <div class="right ml-auto">
              <b-dropdown
                class="ml-2 d-inline-block dropdown-menu-right default_style_dropdown checkbox_dropdown results_dropdown"
                :no-caret="true"
                ref="resultsDropdown">
                <div class="dropdown_header rounded_shape d-flex align-items-center  "
                     slot="button-content">
                  <span class="text">{{ resultsText }}</span>
                  <span class="arrow_icon ml-auto">
                                                <i class=" fal fa-angle-down ml-3"></i>
                                             </span>
                </div>
                <ul class="inner results_filter_dropdown">
                  <li :key="index" v-for="(item,index) in [10, 20, 30, 50, 100]" class="list_item_li"
                      @click="results(item)">
                    {{ item }} Results
                    <i v-if="payload.limit==item" class="far fa-check"></i>
                  </li>
                </ul>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="box_content">
          <pixel-list :payload="payload"></pixel-list>

        </div>

      </div>
      <template v-if="pagination.totalItems > pagination.limit">
        <pagination
          :clickedMethod="paginatePixels"
          :resultCount="pagination.totalItems"
          :itemsPerPage="pagination.limit"
          :currentPage="pagination.page"
          :totalPagesNumber="pagination.totalPages"
        ></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import {} from '@/config/config.js'
import { mapGetters, mapActions } from 'vuex'
import AddPixelModal from './dialogs/AddPixelModal'
import PixelList from './PixelList'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    AddPixelModal,
    PixelList
  },
  data () {
    return {
      size: '14px',
      color: DARK_PURPLE_LOADER_COLOR,
      colorWhite: '#ffffff',
      pixelID: 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id',
      pixelPlaceholder: 'Pixel ID e.g: 277632452644288',
      resultsText: 'Results Per Page',
      payload: {
        search: '',
        page: 1,
        limit: 10,
        sort: 'created_desc'
      },
      pagination: {}
    }
  },
  async created () {
    this.getSiteDetails
    const params = this.getParamsFromURL()
    this.payload.search = params.search
    this.payload.page = params.page
    this.payload.limit = params.limit
    if (this.payload.search && this.payload.search.length > 0) this.payload.page = 1
    if (this.payload.limit && this.payload.limit > 10) {
      this.resultsText = this.payload.limit + ' Results'
      this.payload.page = 1
    }
    const pagination = await this.fetchFilteredPixels(this.payload)
    this.setRetargetingPixels(pagination)
  },
  methods: {
    ...mapActions(['setCallToActionSavingDetails','fetchFilteredPixels']),
    debounceSearch (event) {
      this.$router.replace({ query: { ...this.$route.query, search: event.target.value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.search = event.target.value
      this.payload.page = 1
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        const pagination = await this.fetchFilteredPixels(this.payload)
        this.setRetargetingPixels(pagination)
      }, 600)
    },
    setRetargetingPixels (pagination) {
      this.pagination = {
        page: pagination.current_page,
        totalItems: pagination.total,
        limit: pagination.per_page,
        totalPages: pagination.last_page
      }
    },
    async paginatePixels (page) {
      this.$router.replace({ query: { ...this.$route.query, page: page } })
      this.payload.page = page
      this.pagination.page = page
      const pagination = await this.fetchFilteredPixels(this.payload)
      this.setRetargetingPixels(pagination)
    },
    async results (value) {
      this.resultsText = value + ' Results'
      this.$refs.resultsDropdown.hide()
      this.$router.replace({ query: { ...this.$route.query, limit: value } })
      this.$router.replace({ query: { ...this.$route.query, page: 1 } })
      this.payload.limit = value
      this.payload.page = 1
      const pagination = await this.fetchFilteredPixels(this.payload)
      this.setRetargetingPixels(pagination)
    }
  },
  computed: {
    ...mapGetters([
      'getPixelList'
    ])
  },

  watch: {}

})
</script>
