<template>
  <b-modal modal-class="modal_basic addPixelModal " id="addPixelModal" hide-footer hide-header>
    <img @click="$bvModal.hide('addPixelModal')" class="close_modal" src="/assets/img/cross.svg" alt="">

    <div class="basic_form modal_content">

      <div class="heading">
        <h3 v-if="getAddPixel._id">Edit your Retargeting Pixel</h3>
        <h3 v-else>Add your Retargeting Pixel</h3>
      </div>


      <b-dropdown ref="retargeting_dropdown" right class="dropdown-menu-right default_style_dropdown" :no-caret="true">
        <div class="dropdown_header br-10 rounded_shape d-flex align-items-center" slot="button-content"
             data-cy="select-camp">
          <span class="text text-capitalize">
            <template v-if="!getAddPixel.type">Select Network</template>
            <template v-else>
                <div class="d-flex text-capitalize name">
                <div class="social_icons_square mr-2 align_center">
                    <span class="icon" :class="getPixelSocialClass(getAddPixel.type,1)">
                      <i :class="getPixelSocialClass(getAddPixel.type,2)"></i>
                  </span>
                </div>
                {{ getSplittedText(getAddPixel.type, '_', ' ') }}
              </div>
            </template>
          </span>
          <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
        </div>
        <ul id="campaign-dropdown-scroll" class="clear inner">
          <template>
            <li class="list_item_li pl-1" v-for="pixel in pixelList" @click="$refs.retargeting_dropdown.visible = false"
                @click.prevent="selectPixel(pixel),$refs.retargeting_dropdown.hide(true)">

              <div class="d-flex align-items-center profile__box">
                <div class="content">
                  <div class="d-flex text-capitalize name">
                    <div class="social_icons_square mr-2 align_center">
                      <span class="icon" :class="getPixelSocialClass(pixel,1)">
                      <i :class="getPixelSocialClass(pixel,2)"></i>
                  </span>
                    </div>
                    {{ getSplittedText(pixel, '_', ' ') }}
                  </div>
                </div>
              </div>
            </li>
          </template>
        </ul>
      </b-dropdown>


      <div :class="{'input-field-error' : validations.name || validations.nameLength}"
           class=" mt-3 input_icon_text d-flex align-items-center profile__box">
        <div class="content">
          <div class="url">Pixel Name</div>
          <div class="name">
            <div class="validation_input">
              <input type="text"
                     placeholder="Your pixel name"
                     v-model="getAddPixel.name" data-cy="p-name">
            </div>
          </div>
        </div>
      </div>
      <span class="input-error" v-if="validations.name">{{ messages.name }}</span>
      <span class="input-error" v-else-if="validations.nameLength">{{ messages.nameLength }}</span>
      <template v-if="getAddPixel.type === 'custom'">

        <div :class="{'input-field-error' : validations.pixel || validations.pixelLength}"
             class="mt-3 input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url">Retargeting Script</div>
            <div class="name">
              <div class="validation_input">
                                 <textarea rows="8"
                                           placeholder="e.g <script> ...retargeting code...<script>"
                                           v-model="getAddPixel.pixel" type="text"
                                           class=" txt-left-left " data-cy="p-script"></textarea>
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="validations.pixel">{{ messages.pixel }}</span>
        <span class="input-error" v-else-if="validations.pixelLength">{{ messages.pixelLength }}</span>
      </template>
      <template v-else>
        <!-- While adding google pixels we are required to select allowed google pixels -->
        <div v-if="getAddPixel.type === 'google'"
             class="checkbox-list mb-3 mt-3 d-flex align-items-center justify-content-between">
          <div class="list-item d-flex align-items-center">
            <div class="radio_input_image">
              <input :checked="getAddPixel.key === googlePixelsType['google_adwords']"
                     @click="selectGooglePixelType(googlePixelsType['google_adwords'])"
                     type="radio" name=""
                     id="google_adwords">
              <label for="google_adwords" class="no_text"></label>
            </div>
            <p class="text">Google Adwords</p>
          </div>

          <div class="list-item d-flex align-items-center">
            <div class="radio_input_image">
              <input :checked="getAddPixel.key === googlePixelsType['ga4']" type="radio"
                     @click="selectGooglePixelType(googlePixelsType['ga4'])" id="ga4">
              <label for="ga4" class="no_text"></label>
            </div>
            <p class="text">GA4</p>
          </div>

          <div class="list-item d-flex align-items-center">
            <div class="radio_input_image">
              <input :checked="getAddPixel.key === googlePixelsType['universal_analytics']" type="radio"
                     @click="selectGooglePixelType(googlePixelsType['universal_analytics'])" id="universal_analytics">
              <label for="universal_analytics" class="no_text"></label>
            </div>
            <p class="text">Universal Analytics</p>
          </div>

          <div class="list-item d-flex align-items-center">
            <div class="radio_input_image">
              <input :checked="getAddPixel.key === googlePixelsType['google_tag_manager']" type="radio"
                     @click="selectGooglePixelType(googlePixelsType['google_tag_manager'])" id="google_tag_manager">
              <label for="google_tag_manager" class="no_text"></label>
            </div>
            <p class="text">Google Tag Manager</p>
          </div>

        </div>

        <div :class="{'input-field-error' : validations.pixel || validations.pixelLength}"
             class="mt-3 input_icon_text d-flex align-items-center profile__box">
          <div class="content">
            <div class="url d-flex align-items-center">
              Your Pixel ID
              <a v-if="!getSiteDetails.isWhiteLabel" style="color:#3988fe;" class="ml-auto" target="_blank"
                 :href="pixel_documentation_link">How
                do i find my <span
                  class="capitalize__text" style="color:#3988fe;">{{ getAddPixel.type }}</span>
                Pixel ID?</a>
            </div>
            <div class="name">
              <div class="validation_input d-flex">
                <span v-if="getAddPixel.type === 'google'"
                      style="color: rgb(57, 136, 254);">{{ googlePixelsTypeKey[getAddPixel.key] }}</span>
                <input :class="{'input-field-error' : validations.pixel}" type="text"
                       :placeholder="pixel_placeholder"
                       v-model="getAddPixel.pixel" data-cy="p-id">
              </div>
            </div>
          </div>
        </div>
        <span class="input-error" v-if="validations.pixel">{{ messages.pixel }}</span>
        <span class="input-error" v-else-if="validations.pixelLength">{{ messages.pixelLength }}</span>
      </template>

      <div class=" btn_block text-left">

        <button @click.prevent="validateAndStorePixel()" :disabled="getPixelLoaders.add"
                class="btn---cta btn-blue with-shadow btn-round  btn--loader white--loader loader--right btn-bold">

                    <span>
                            <template v-if="getAddPixel._id">
                                Save
                            </template>
                            <template v-else>
                                Add
                            </template>
                        </span>
          <clip-loader v-if="getPixelLoaders.add" :size="'14px'" :color="'#ffffff'"></clip-loader>
        </button>
        <button type="button" class="ml-2 btn---cta light-blue btn-round  btn-bold"
                @click="$bvModal.hide('addPixelModal')"><span>Close</span>
        </button>
      </div>

    </div>
  </b-modal>

</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import {
  pixelMessages,
  pixelValidations,
  googlePixelsType,
  googlePixelsTypePlaceHolder,
  googlePixelsTypeKey
} from '../../../../../common/attributes'
import {campaignTypes, pixelTypes} from "@/state/modules/mutation-types";

export default ({
  data () {
    return {
      pixel_documentation_link: 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id',
      pixel_placeholder: 'Pixel ID e.g: 277632452644288',
      helpscout_article_id: '147',
      validations: pixelValidations,
      messages: pixelMessages,
      pixelList: ['facebook', 'twitter', 'linkedin', 'pinterest', 'quora', 'google', 'tiktok', 'custom'],
      googlePixelsType: googlePixelsType,
      googlePixelsTypePlaceHolder: googlePixelsTypePlaceHolder,
      googlePixelsTypeKey: googlePixelsTypeKey
    }
  },
  props: [
    'callType',
    'addPixel',
    'pixelListKey'
  ],
  computed: {
    ...mapGetters([
      'getAddPixel',
      'getPixelLoaders',
      'getCampaignAdd',
      'getCampaigns'
    ])

  },
  methods: {
    ...mapActions(['storePixel']),
    async validateAndStorePixel () {
      this.validations.name = this.requiredCheck(this.getAddPixel.name)
      this.validations.pixel = this.requiredCheck(this.getAddPixel.pixel)
      let result = Object.keys(this.validations).every(k => this.validations[k] === false)

      if (result) {
        this.getAddPixel.key = (this.getAddPixel.type === 'google') ? this.googlePixelsTypeKey[this.getAddPixel.key] : ''

        let res = await this.storePixel()
        if (res && ((res.hasOwnProperty('data') && res.data.status) || (res.hasOwnProperty('_id') && res._id))) {
          this.$bvModal.hide('addPixelModal')

          if (this.callType === 'bio_links') {
            this.$store.commit(pixelTypes.SET_PIXEL)
            this.ctaPixelAction(res._id)
            return
          }

          if (this.getCampaigns.pixel_add_check) {
            this.$store.commit(campaignTypes.SET_CAMPAIGN_PIXEL_ADD_CHECK, false)
            this.ctaPixelAction(res._id)
          }
        }

      }
    },

    ctaPixelAction (pixelId) {
      // selection
      let selection = this.addPixel
      if (!selection[this.pixelListKey]) {
        selection[this.pixelListKey] = []
      }

      if (selection[this.pixelListKey].includes(pixelId)) {
        let index = selection[this.pixelListKey].indexOf(pixelId)
        selection[this.pixelListKey].splice(index, 1)
      } else {
        let retargetingIds = selection[this.pixelListKey]
        retargetingIds.push(pixelId)
        this.$store.commit(campaignTypes.SET_CAMPAIGN_RETARGETING_PIXEL_LIST, (JSON.parse(JSON.stringify(retargetingIds))))
      }
    },

    showBeaconModal () {
      Beacon('article', this.helpscout_article_id, {type: 'modal'})
    },

    selectPixel (pixel) {
      this.getAddPixel.type = pixel
    },

    selectGooglePixelType (key) {
      this.getAddPixel.key = this.googlePixelsType[key]
    }
  },
  watch: {
    'getAddPixel.name' (value) {
      if (value && value.length > 0) {
        this.validations.name = false
        this.validations.nameLength = !this.maxLength(value, 255)
      }
    },
    'getAddPixel.pixel' (value) {
      if (value && value.length > 0) {
        this.validations.pixel = false
        this.validations.pixelLength = !this.maxLength(value, 2000)
      }
    },

    'getAddPixel.type' (value) {
      switch (value) {
        case 'facebook':
          this.pixel_documentation_link = 'https://docs.replug.io/article/147-how-to-find-facebook-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 277632452644288'
          this.helpscout_article_id = '147'
          break
        case 'twitter':
          this.pixel_documentation_link = 'https://docs.replug.io/article/148-how-to-find-my-twitter-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: nzgab'
          this.helpscout_article_id = '148'
          break
        case 'linkedin':
          this.pixel_documentation_link = 'https://docs.replug.io/article/174-how-to-find-my-linkedin-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 248483'
          this.helpscout_article_id = '174'
          break
        case 'pinterest':
          this.pixel_documentation_link = 'https://docs.replug.io/article/176-how-to-find-my-pinterest-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 2617452371077'
          this.helpscout_article_id = '176'
          break
        case 'quora':
          this.pixel_documentation_link = 'https://docs.replug.io/article/175-how-to-find-my-quora-pixel-id'
          this.pixel_placeholder = 'Pixel ID e.g: 8f0a82ab5218460e89cbfbc72b394c72'
          this.helpscout_article_id = '175'
          break
        case 'google':
          this.pixel_documentation_link = 'https://docs.replug.io/article/177-how-to-find-my-google-adwords-pixel-id'
          this.pixel_placeholder = `${this.googlePixelsTypePlaceHolder[this.getAddPixel.key]}`
          this.helpscout_article_id = '177'
          break
        case 'custom':
          this.pixel_documentation_link = ''
          this.pixel_placeholder = ''
      }
    },

    'getAddPixel.key' (value) {
      this.pixel_placeholder = `${this.googlePixelsTypePlaceHolder[value]}`
    }
  }
})
</script>
