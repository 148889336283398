<template>
  <div class="table_container">
    <table class="table   list-content">
      <thead class=" ">
      <tr>

        <th class="" style="width: 230px;">Name</th>
        <th class="" style="width: 230px;">Network</th>
        <th class="" style="width: 230px;">Pixel ID</th>
        <th class="" style="width: 200px;">Created</th>
        <th style="width: 200px;text-align: right;">Actions</th>
      </tr>
      </thead>
      <tbody class="list-content-body">

      <!-- TODO: change with the pixel retrieve...
        <tr  v-if="getCallToActionsLoader.fetchPixelLoader">
            <td colspan="5">
                <p class="text-center p-4">
                    <clip-loader :size="size" :color="color"></clip-loader>
                </p>
            </td>
        </tr>-->

      <tr v-if="getPixelLoaders.get">
        <td colspan="5">
          <clip-loader
            :size="'20px'"
            :color="color"
          ></clip-loader>
        </td>
      </tr>
      <tr v-else-if="!getPixelLoaders.get && getFilteredPixelList.length == 0">
        <td colspan="9">
          <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
              getFilteredMessage('Pixel', payload.search, 'All Time', payload.archive)
            }}</p>
        </td>
      </tr>
      <template v-else>
        <tr v-for="(pixel,index) in getFilteredPixelList">
          <td class="d-flex" style="text-align: left;">
            <span v-tooltip="pixel.name && pixel.name.length >= 20 ? pixel.name : ''" class="pixel-script">{{
                pixel.name
              }}</span>
          </td>
          <td>
            <div class="social_icons_square align_center">
              <span class="icon" :class="getPixelSocialClass(pixel.type,1)">
                <i :class="getPixelSocialClass(pixel.type,2)"></i>
              </span>

              <span class="text mr-1">{{
                  getSplittedText(pixel.type, '_', ' ')
                }}</span>
              <span>{{getGooglePixelOnKey(pixel.type, pixel.key, googlePixelsTypeKey)}}</span>
            </div>

          </td>
          <td class="d-flex">
            <span v-tooltip="pixel.pixel && pixel.pixel.length >= 20 ? pixel.pixel : ''"
                  class="pixel-script">{{ pixel.pixel }}</span>
          </td>
          <td class="created_at">
            {{ pixel.created_at | relativeCaptilize }}
          </td>

          <td class="table-action ">
            <i v-tooltip="'Edit Pixel'" class="far fa-pencil  "
               @click.prevent="editPixel(pixel)" aria-hidden="true"></i>
            <i v-tooltip="'Remove Pixel'" class="far fa-trash "
               @click.prevent="deletePixel({pixelId: pixel._id,indexId: index})" aria-hidden="true"></i>
          </td>
        </tr>
      </template>

      </tbody>

    </table>
  </div>

</template>
<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'
import {pixelTypes} from '@/state/modules/mutation-types'
import {DARK_PURPLE_LOADER_COLOR} from '@/common/constants'
import {googlePixelsTypeKey} from '@/common/attributes'

export default ({
  computed: {
    ...mapGetters(['getFilteredPixelList', 'getPixelLoaders']),
  },
  props: [
    'payload'
  ],
  data: function () {
    return {
      color: DARK_PURPLE_LOADER_COLOR,
      googlePixelsTypeKey: googlePixelsTypeKey
    }
  },
  methods: {
    ...mapMutations([pixelTypes.SET_PIXEL]),
    ...mapActions(['deletePixel', 'fetchPixels']),
    editPixel (pixel) {
      this[pixelTypes.SET_PIXEL](JSON.parse(JSON.stringify(pixel)))
      // $('#addPixelModal').modal('show')
      this.$bvModal.show('addPixelModal')
    }
  }
})

</script>
<style lang="less" scoped>
.pixel-script {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
